import React, { useEffect, useState, useRef } from 'react';
import { Card, Tabs, Button, Table, Modal, Input, Popover, Space } from 'antd';
import jsPDF from 'jspdf';
import { getBookingHistorys } from '../../services/EndPoint';
import 'jspdf-autotable';
import { Link } from 'react-router-dom';
import BookingDetatailsComp from './BookingDetatailsComp';
import { SearchOutlined } from '@ant-design/icons';
import HotelCancellationModal from './HotelCancellationModal';
import moment from 'moment';
import { CalendarOutlined } from '@ant-design/icons';
import { logoHotel, person } from '../../utils/Imagepath';
import BookingReceipt from './BookingReceipt';
import html2canvas from 'html2canvas';
import './BookingReceipt.scss'; // Import the CSS file for styling
import { resultRoomPlan } from '../../utils/Constants';

const { TabPane } = Tabs;

const BookingHistory = () => {
    const [tabValue, setTabValue] = useState('all');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [bookingDetails, setBookingDetails] = useState(null);
    const [bookingDetailsData, setBookingDetailsData] = useState("");
    // const [selectedBookingIds, setSelectedBookingIds] = useState([]);
    const onChange = (key) => {
        setTabValue(key);
    };
    const userId = JSON.parse(localStorage.getItem('userId'));
    const emailAddress = JSON.parse(localStorage.getItem('emailAddress'));

    const handleClick = (value) => {
        setBookingDetailsData(value);
        setBookingDetails(true);
    };

    const [dataRender, setRender] = useState("")
    useEffect(() => {
        setLoading(true);

        let params = {
            userId: emailAddress,
            Type: tabValue ? tabValue : "completed",
        };
        getBookingHistorys(params)
            .then((req) => {
                setLoading(false);
                setData(req?.bookinglist);
            })
            .catch((err) => {
                console.log("Error fetching bookings:", err);
                setLoading(false);
            });
    }, [tabValue, dataRender]);


    // const handleRowSelection = (selectedRowKeys, selectedRows) => {
    //     const selectedIds = selectedRows.map((row) => row.booking_id);
    //     setSelectedBookingIds(selectedIds);
    // };



    const [selectedBookingIds, setSelectedBookingIds] = useState([]);
    const printRef = useRef();

    const handleRowSelection = (selectedRowKeys, selectedRows) => {
        const selectedIds = selectedRows.map((row) => row.booking_id);
        setSelectedBookingIds(selectedIds);
    };


    const getRateplanDescription = (rateplanCode) => {
        const match = rateplanCode?.match(/-(cp|ep)$/);
        return match ? resultRoomPlan[match[1]] : "";
    };


    const generatePDF = async () => {
        const pdf = new jsPDF('portrait', 'px', 'a4');

        for (let i = 0; i < selectedBookings.length; i++) {
            const booking = selectedBookings[i];
            const bookingHtml = `
                <div class="print-container">
                    <div class="booking-container">
                        <div class="header">
                            <img src="${logoHotel}" alt="Booking Logo" />
                                                <div style="display: flex; align-items: center; margin-top: 15px; font-size: 16px;">
                        <div style="width: 40px; height: 40px; background-color: #007bff; color: white; font-size: 18px; font-weight: bold; border-radius: 50%; display: flex; justify-content: center; align-items: center; margin-right: 10px;">
                           ${i + 1}
                        </div>
                        <div style="font-size: 16px; font-weight: bold; color: #333;">
                            <span style="color: #007bff; font-size: 14px; font-weight: normal;">Booking ID:</span> <br />
                            ${booking.booking_id}
                        </div>
                    </div>

                        </div>
    
                        <div class="section-title mt-4">YOUR DETAILS</div>
                        <table>
                            <tbody>
                                <tr class="py-2">
                                    <th>Name</th>
                                    <td>${booking.user_first_name} ${booking.user_last_name}</td>
                                </tr>
                                <tr>
                                    <th>Email Address</th>
                                    <td>${booking.user_email}</td>
                                </tr>
                                <tr>
                                    <th>Mobile Number</th>
                                    <td>${booking.user_mobile} </td>
                                </tr>
                            </tbody>
                        </table>
    
                        <div class="section-title mt-4">BOOKING DETAILS</div>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Booking ID</th>
                                    <td>${booking.booking_id}</td>
                                </tr>
                                <tr>
                                    <th>Reference ID </th>
                                    <td>${booking.pg_reference_no}</td>
                                </tr>
                                <tr>
                                    <th>Check-in</th>
                                    <td>${booking.booking_from}</td>
                                </tr>
                                <tr>
                                    <th>Check-out</th>
                                    <td>${booking.booking_to}</td>
                                </tr>
                               
                                <tr>
                                    <th>Number of Nooms<br /> With Room Category</th>
                                    <td>${booking.room_name}</td>
                                </tr>
                                
                                  <tr>
                                    <th>Booking Status</th>
                                    <td>${booking.booking_status}</td>
                                </tr>
                                  <tr>
                                    <th>Payment Status</th>
                                    <td>${booking.payment_status}</td>
                                </tr>
                                <tr>
                                    <th>Total Amount</th>
                                    <td class="amount-paid">${booking.amount}</td>
                                </tr>
                            </tbody>
                        </table>

                         <div class="section-title mt-4">HOTEL DETAILS</div>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Hotel Name</th>
                                    <td>The Superb Hotel</td>
                                </tr>
                                <tr>
                                    <th>Property Address</th>
                                    <td> 5-9-18/9 Saifabad, Opposite - Old Gate Secretariat, Hyderabad</td>
                                </tr>
                                  <tr>
                                    <th>Email </th>
                                    <td>reservation@superbhotel.in</td>
                                </tr>
                                 <tr>
                                    <th>Contact </th>
                                    <td>+040-29388551, +918448092359</td>
                                </tr>
                                 <tr>
                                    <th>Website </th>
                                    <td><a href="https://superbhotel.in" target="_blank" rel="noopener noreferrer">https://superbhotel.in</a></td>
                                </tr>
                            </tbody>
                        </table>
    
                      <div class="footer">
                       <div style="background-color: #333; color: white; padding: 30px; text-align: center; margin-top:57.1px">
                           <p style="margin: 0;">© Copyrights 2024 The Superb Hotels All rights reserved.</p>
                       </div>
                    </div>

                        </div>
                    </div>
                </div>
            `;

            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = bookingHtml;
            document.body.appendChild(tempDiv);

            const canvas = await html2canvas(tempDiv, { scale: 2 });
            const imgData = canvas.toDataURL('image/png');

            const imgWidth = 595.28;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

            if (i < selectedBookings.length - 1) {
                pdf.addPage();
            }
            document.body.removeChild(tempDiv);
        }

        // Save the PDF
        pdf.save(`${tabValue}.pdf`);
    };


    const selectedBookings = data.filter((item) => selectedBookingIds.includes(item.booking_id));



    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        setSearchedColumn('');
        setSearchText('')
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#0000FF' : "#0000FF",
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },

    });


    const columns = [

        {
            title: 'Booking ID',
            dataIndex: 'booking_id',
            key: 'booking_id',
            ...getColumnSearchProps('booking_id'),
            render: (text, value) => (
                <Popover
                    content={<div style={{ fontSize: "12px", padding: "3px 12px" }}>View Details</div>}
                    placement="right"
                    overlayClassName="popover-text-style"
                >
                    <Link className="link-button" onClick={() => handleClick(value)}>
                        {text}
                    </Link>
                </Popover>
            ),
        },

        {
            title: 'Guest Name',
            dataIndex: 'guest_name',
            key: 'guest_name',
            render: (text, record) => `${record.user_first_name}  ${record.user_last_name}`,
        },

        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },

        {
            title: 'Payment Status',
            dataIndex: 'payment_status',
            key: 'payment_status',
            render: (text, record) => {
                if (record?.payment_status === "Aborted") {
                    return "Failed";
                } else if (record?.payment_status === null) {
                    return null;
                } else if (record?.payment_status) {
                    return record?.payment_status;
                } else {
                    return "-";
                }
            }
        },


        {
            title: 'Booking Status',
            dataIndex: 'booking_status',
            key: 'booking_status',
            render: (text, record) => (
                <>
                    {record.booking_status === 'canceled' && (
                        <Button className='cancel_button text-capitalize px-4' type="text">
                            {record?.booking_status}
                        </Button>
                    )}
                </>
            )
        },

        {
            title: 'Check in - Checkout Date',
            key: 'checkDates',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CalendarOutlined style={{ color: '#1890ff', marginRight: '8px' }} />
                    <span>
                        {`${moment(record.booking_from).format('DD MMM, YYYY')} - ${moment(record.booking_to).format('DD MMM, YYYY')}`}
                    </span>
                </div>
            ),
        },

        {
            title: 'Action',
            key: 'action',
            render: (text, record) => {
                const currentDate = moment();
                const isUpcomingOrToday = moment(record.booking_from).isSameOrAfter(currentDate, 'day');
                const isCancelled = record?.booking_status === 'canceled';
                const isSuccess = record?.payment_status === 'Success';
                // console.log("booking_status", record?.booking_status)

                // Show "Cancel Booking" button only if:
                // 1. Booking is upcoming or today (tabValue is 'upcoming' or isUpcomingOrToday is true)
                // 2. Booking is not cancelled
                if ((tabValue === 'upcoming' || isUpcomingOrToday) && isSuccess) {
                    return (
                        <Button className='cancel_button' onClick={() => showModal(record)} type="text">
                            Cancel Booking
                        </Button>
                    );
                }
                return null
            }
        }


    ];

    const OperationsSlot = {
        right:
            <div className='download_pdf_btn'>
                <Button type="primary" className='text-end' onClick={generatePDF} disabled={selectedBookingIds.length === 0}>
                    Download
                </Button>
            </div>
        ,

    };


    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = (record) => {
        // console.log("handleClick", record)
        setIsModalVisible(true);
        setBookingDetailsData(record);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    return (
        <div>

            <nav style={{ padding: '15px', background: '#eee', marginBottom: '30px', width: '100%', height: '63px' }} aria-label="breadcrumb">
                <div className="container">
                    <ol className="breadcrumb me-3" style={{ float: 'right' }}>
                        <li className="breadcrumb-item"><a href="/home" style={{ color: '#000' }}>Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Booking History</li>
                    </ol>
                </div>
            </nav>

            <div className="container my-5">
                <h1 className='main-heading mb-4'>My Bookings</h1>
                <Card>
                    {/* <BookingReceipt /> */}
                    <Tabs defaultActiveKey="all" className='responsiveTable' onChange={onChange} tabBarExtraContent={OperationsSlot}>
                        <TabPane tab="All Bookings" className='' key="all">
                            <Table
                                rowSelection={{
                                    type: 'checkbox',
                                    onChange: handleRowSelection,
                                }}
                                dataSource={data}
                                columns={columns}
                                loading={loading}
                                rowKey="booking_id"
                            />
                        </TabPane>
                        <TabPane tab="Completed Bookings" key="completed">
                            <Table
                                rowSelection={{
                                    type: 'checkbox',
                                    onChange: handleRowSelection,
                                }}
                                dataSource={data}
                                columns={columns}
                                loading={loading}
                                rowKey="booking_id"
                            />
                        </TabPane>
                        <TabPane tab="Upcoming Bookings" key="upcoming">
                            <Table
                                rowSelection={{
                                    type: 'checkbox',
                                    onChange: handleRowSelection,
                                }}
                                dataSource={data}
                                columns={columns}
                                loading={loading}
                                rowKey="booking_id"
                            />
                        </TabPane>
                        <TabPane tab="Cancelled Bookings" key="canceled">
                            <Table
                                rowSelection={{
                                    type: 'checkbox',
                                    onChange: handleRowSelection,
                                }}
                                dataSource={data}
                                columns={columns}
                                loading={loading}
                                rowKey="booking_id"
                            />
                        </TabPane>
                    </Tabs>

                </Card>
            </div>

            <Modal
                open={bookingDetails}
                footer={false}
                onCancel={() => setBookingDetails(false)}
                centered
            >
                <BookingDetatailsComp setBookingDetails={setBookingDetails} bookingDetailsData={bookingDetailsData} />
            </Modal>


            <HotelCancellationModal
                item={bookingDetailsData}
                isModalVisible={isModalVisible}
                showModal={showModal}
                handleOk={handleOk}
                setRender={setRender}
                handleCancel={handleCancel}
            />
        </div>
    );
};

export default BookingHistory;
