import React from 'react';
import '../../Hotel-Policy/HotelPolicy.scss';

function PaymentPolicy() {
    return (
        <>
            <div className='container'>
                <div className="row">

                    <div className="col-lg-12">
                        <div className="policy-main shadow-sm mb-5">
                            <div className="policy-header position-relative">
                                <h3 className="heading">Payment &amp; Cancellation Policy</h3>
                                <div className="position-absolute w-100 z-index-1 bottom-0 ms-n5" style={{ left: '0' }}>
                                    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" style={{ height: '7vh', minHeight: '50px' }}>
                                        <defs>
                                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                                        </defs>
                                        <g className="moving-waves">
                                            <use xlinkHref="#gentle-wave" x="48" y="-1" fill="rgba(255,255,255,0.40"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="16" fill="rgba(255,255,255,0.95"></use>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className="policy-body p-4">
                                <p>Thank you for considering Superb Hotel for your upcoming stay. To ensure a seamless booking experience and clarify our payment and cancellation procedures, we've outlined our policies below:</p>

                                <h6>Payment Policy :</h6>
                                <ol>
                                    <li><span>Booking Confirmation : </span>
                                        To secure your reservation, we require valid credit & debit card details during the booking process. This card will be authorized for the total cost of your stay, including taxes and any additional charges.
                                    </li>
                                    <li><span>Deposit Requirement : </span>
                                        Depending on the type of reservation or during peak seasons, a deposit may be necessary to confirm your booking. The specific deposit amount and terms will be communicated to you at the time of booking.
                                    </li>
                                    <li><span>Accepted Payment Methods : </span>
                                        We accept debit card, UPI, credit cards, such as Visa, MasterCard, American Express, and One bank. Cash payments are also accepted upon check-in, alongside a valid credit card for incidentals.
                                    </li>
                                    <li><span>Currency : </span>
                                        All rates and charges are displayed in the local currency, unless stated otherwise. For international transactions, your bank may apply currency conversion rates, potentially causing slight variances from the listed amount.
                                    </li>
                                </ol>
                                <h6>Cancellation &amp; Modification Policy :</h6>
                                <ol>
                                    <li>
                                        Failure to check-in on the scheduled date of check-in may result in “No show” and full
                                        amount will be forfeited.
                                    </li>
                                    <li>
                                        The cancellation of booking is not allowed within/less than 48 hrs. of the date of check-in
                                        and the full amount will be forfeited.

                                    </li>

                                    <li>
                                        However, the cancellation of booking  from 15 or more days, in advance, before the check-
                                        in-date will be free except transaction processing charges.
                                    </li>
                                    <li>
                                        Cancellations made between 48 hrs. and  less than 15 days,  in advance,  from the check- in-
                                        date will incur a cancellation charge of one night tariff, taxes and transaction processing
                                        charges.
                                    </li>
                                </ol>

                                <h6>Refund Policy :</h6>
                                <ol>
                                    <li>Refund, if any, may take a processing period of  10 to 15 working days.
                                    </li>
                                    <li> Refunds, if any, will be credited via the same mode as used while making the bookings.  </li>

                                </ol>
                                <h6>Special Packages &amp; Promotions :</h6>
                                <ol>
                                    <li><span>Special Offers : </span>
                                        Certain packages or promotional rates might have distinct terms and conditions concerning payment and cancellation. Please review the specific terms associated with each offer during the booking process.
                                    </li>
                                    <li><span>Group Bookings : </span>
                                        For group reservations or bookings involving multiple rooms, alternative payment and cancellation policies might apply. Our booking team will provide detailed information upon inquiry.
                                    </li>
                                </ol>
                                <h6>Policy Acknowledgement :</h6>
                                <p>By confirming your booking at Superb Hotel, you acknowledge and agree to the payment, cancellation, and modification policies outlined above. We recommend reviewing these policies carefully beforefinalizing your booking.</p>
                                <p>Our team is available to assist you with any queries, clarifications, or special requests regarding our policies. We are committed to ensuring your stay with us is enjoyable and hassle-free.</p>

                                <p>Thank you for choosing  <b style={{ fontWeight: 500 }}>Superb Hotel</b> for your upcoming visit. We eagerly anticipate providing you with an exceptional experience.</p>
                                <br />
                                <div className='contactDetail'>
                                    <span>Contact Us</span>
                                    <p>If you have any questions about this Privacy Policy, You can contact us:</p>

                                    <p><span>By email :</span> reservation@superbhotel.in</p>
                                    <p><span>By visiting this page on our website :</span> http://www.superbhotel.in/contact</p>
                                    <p><span>By phone number :</span> 040-29388551</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentPolicy;